import { useNavigate } from 'react-router-dom';
import { Button } from '../../common/Button/Button';
import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './HeaderSection.module.scss';

export const HeaderSection = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <Typography variant="h1" className={styles.title}>
        Oops! Page not found
      </Typography>
      <Spacer h={32} />
      <Typography variant="h4">404 – You’ve found a page that doesn’t exist</Typography>
      <Spacer h={8} />
      <Typography variant="pMediumRegular">
        Breathe in, and on the out breath, go back and try again.
      </Typography>
      <Spacer h={32} />
      <Button title="Back to home" onClick={() => navigate('/')} />
    </div>
  );
};
