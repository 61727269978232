/* eslint-disable react/jsx-no-bind */
import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { Typography } from '../Typography/Typography';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import stylesSass from './Dropdown.module.scss';

interface DropDownProps {
  onChange: (newLocaleName: IItem) => void;
  title: string;
  list: IItem[];
  currentLocale: string;
  sectionClass: string;
}

interface IItem {
  value: string;
  label: string;
}

export const Dropdown = (props: DropDownProps) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleList = () => {
    setIsListOpen((prev) => !prev);
  };

  const handleClick = useCallback(
    (item: IItem) => {
      toggleList();
      props.onChange(item);
    },
    [props],
  );

  useOutsideClick([ref], () => setIsListOpen(false));

  const listItems = useMemo(() => {
    const { list, currentLocale } = props;

    if (list.length) {
      return list.map((item) => (
        <button
          key={item.value}
          type="button"
          className={cn(stylesSass.ddListItem, {
            [stylesSass.currentItemMarker]: item.value.toLowerCase() === currentLocale,
          })}
          onClick={handleClick.bind(this, item)}
        >
          <span
            className={cn({
              [stylesSass.currentItemMarker]: item.value.toLowerCase() === currentLocale,
            })}
          >
            {item.label}
          </span>
        </button>
      ));
    }
  }, [props, handleClick]);

  return (
    <div ref={ref} className={cn(stylesSass.ddWrapper, props.sectionClass)}>
      <button type="button" className={cn(stylesSass.ddHeader)} onClick={toggleList}>
        <div className={cn(stylesSass.ddHeaderTitle)}>
          <Typography>
            {props.currentLocale[0].toUpperCase() + props.currentLocale.slice(1)}
          </Typography>
        </div>
      </button>
      {isListOpen && (
        <div className={cn(stylesSass.ddList)}>
          <div className={cn(stylesSass.ddScrollList)}>{listItems}</div>
        </div>
      )}
    </div>
  );
};
