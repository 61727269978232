import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './HeaderSection.module.scss';

export const HeaderSection = () => {
  return (
    <div className={styles.root}>
      <Spacer h={32} />
      <div className={styles.wrapper}>
        <Typography variant="h1" className={styles.title}>
          Imprint
        </Typography>
        <Spacer h={16} />
        <Typography variant="pMediumRegular" className={styles.text}>
          This website is jointly provided by Razz Finanzz AG, Brighty Digital UAB and Brighty App
          OU.
        </Typography>
      </div>
    </div>
  );
};
