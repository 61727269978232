import { Card } from '../../common/Card/Card';
import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './InfoSection.module.scss';

export const InfoSection = () => {
  return (
    <div className={styles.root}>
      <Card className={styles.card}>
        <Typography>Razz Finanzz AG</Typography>
        <Spacer h={32} />
        <div className={styles.texts}>
          <Typography variant="pMediumRegular">Mühlegasse 18, 6340 Baar, Switzerland</Typography>
          <Typography variant="pMediumRegular">Register number: CHE-274.335.581</Typography>
          <Typography variant="pMediumRegular">support@brighty.app</Typography>
        </div>
      </Card>
      <Card className={styles.card}>
        <Typography>Brighty Digital UAB</Typography>
        <Spacer h={32} />
        <div className={styles.texts}>
          <Typography variant="pMediumRegular">Vilnius, Laisvės pr. 60</Typography>
          <Typography variant="pMediumRegular">Register number: 306099818</Typography>
          <Typography variant="pMediumRegular">support@brighty.app</Typography>
        </div>
      </Card>
      <Card className={styles.card}>
        <Typography>Brighty App OU</Typography>
        <Spacer h={32} />
        <div className={styles.texts}>
          <Typography variant="pMediumRegular">
            Harju maakond, Tallinn, Kesklinna linnaosa, J. Vilmsi tn 47, 10115
          </Typography>
          <Typography variant="pMediumRegular">Register number: 16355194</Typography>
        </div>
      </Card>
      <Card className={styles.card}>
        <Typography>Supervisory authority</Typography>
        <Spacer h={32} />
        <div className={styles.texts}>
          <Typography variant="pMediumRegular">
            Brighty Digital UAB supervised by FINANCIAL CRIME INVESTIGATION SERVICE under The
            Ministry of the Interior of the Republic of Lithuania https://fntt.lt/en/
          </Typography>
        </div>
      </Card>
      <Card className={styles.card}>
        <Typography>Dispute resolution</Typography>
        <Spacer h={32} />
        <div className={styles.texts}>
          <Typography variant="pMediumRegular">
            Disputes related to providing services shall be resolved by the relevant court of
            Switzerland.
          </Typography>
        </div>
      </Card>
    </div>
  );
};
