import { FC, useMemo } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface IProps {
  w?: number;
  h?: number;
  wm?: number;
  hm?: number;
}

export const Spacer: FC<IProps> = ({ w = 1, h = 1, wm = 0, hm = 0 }) => {
  const isMobile = useMediaQuery('(max-width: 577px)');
  const wMobile = useMemo(() => (wm ? wm : w), [wm, w]);
  const hMobile = useMemo(() => (hm ? hm : h), [hm, h]);

  const styles = {
    view: (isMobile: boolean) => ({
      width: `${isMobile ? wMobile : w}px`,
      height: `${isMobile ? hMobile : h}px`,
    }),
  };

  return <div style={styles.view(Boolean(isMobile))} />;
};
