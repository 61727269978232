import { FC } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useTranslation } from 'react-i18next';
import { i18translateType } from '../../../types/i18translateType';
import BigPhoneDark from '../../../images/big-phone-dark.png';
import BigPhone from '../../../images/big-phone.png';
import { Typography } from '../../common/Typography/Typography';
import { Spacer } from '../../common/Spacer/Spacer';
import AppStore from '../../../images/AppStore.svg';
import GooglePlay from '../../../images/GooglePlay.svg';
import AppStoreDark from '../../../images/AppStore-dark.svg';
import GooglePlayDark from '../../../images/GooglePlay-dark.svg';
import styles from './HeaderSection.module.scss';

interface IProps {
  landing?: boolean;
}

export const HeaderSection: FC<IProps> = ({ landing = false }) => {
  const { t }: i18translateType = useTranslation();
  const { currentTheme } = useThemeSwitcher();

  return (
    <div className={styles.root}>
      <div className={styles.leftColumn}>
        <Typography variant="h1" className={styles.title}>
          {landing ? t('Landing.IntroSection.title') : t('IntroSection.title')}
        </Typography>
        <Spacer h={16} />
        <Typography variant="pLargeRegular" className={styles.text}>
          {landing ? t('Landing.IntroSection.text') : t('IntroSection.text')}
        </Typography>
        <Spacer h={32} hm={16} />
        <div className={styles.buttons}>
          <a
            href="https://app.adjust.com/7d2bnfa?redirect_ios=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
            target="_blank"
            rel="noreferrer"
          >
            <img src={currentTheme === 'light' ? AppStore : AppStoreDark} alt="AppStore" />
          </a>
          <a
            href="https://app.adjust.com/qr3gjca?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
            target="_blank"
            rel="noreferrer"
          >
            <img src={currentTheme === 'light' ? GooglePlay : GooglePlayDark} alt="GooglePlay" />
          </a>
        </div>
      </div>
      <div className={styles.img}>
        <Spacer hm={70} />
        <img src={currentTheme === 'dark' ? BigPhoneDark : BigPhone} alt="Smartphone" />
      </div>
    </div>
  );
};
