import { useEffect, useMemo, useState } from 'react';

export const useMediaQuery = (query: string) => {
  const mediaMatch = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query);
    }
  }, [query]);
  const [matches, setMatches] = useState(mediaMatch?.matches);

  useEffect(() => {
    if (mediaMatch) {
      const handler = (e: any) => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    }
  }, [mediaMatch]);
  return matches;
};
