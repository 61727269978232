/* eslint-disable import/no-default-export */
import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/ImprintPage/HeaderSection/HeaderSection';
import { InfoSection } from '../components/ImprintPage/InfoSection/InfoSection';

const ImprintPage = () => {
  return (
    <>
      <HeaderSection />
      <InfoSection />
    </>
  );
};

export default withTranslation()(ImprintPage);
