import { FC, useCallback, useEffect, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useTranslation } from 'react-i18next';
import CoinsIcon from '../../../images/Coins.svg';
import TransferIcon from '../../../images/Transfer.svg';
import EarnIcon from '../../../images/Earn.svg';
import Coins from '../../../images/Coins.png';
import CoinsDark from '../../../images/CoinsDark.png';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Typography } from '../../common/Typography/Typography';
import { Spacer } from '../../common/Spacer/Spacer';
import styles from './PowerSection.module.scss';

interface IProps {
  landing?: boolean;
}

export const PowerSection: FC<IProps> = () => {
  const { t, i18n }: any = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 576px)');
  const { currentTheme } = useThemeSwitcher();

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;
    const sc = i18n.language === 'de' ? 1272 : 1272;
    if (ref.current) {
      if (scrollTop < sc) {
        ref.current.style.opacity = `0`;
      }
      if (scrollTop > sc + 100) {
        ref.current.style.opacity = `1`;
      }
      if (scrollTop > sc && scrollTop < sc + 100) {
        ref.current.style.opacity = `${(scrollTop - sc) / 100}`;
      }
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!isMobile) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile, handleScroll]);

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.card}>
        <div className={styles.leftColumn}>
          <Typography variant="h1">{t('SocialPowerSection.title')}</Typography>
          <Spacer h={32} />
          <div className={styles.point}>
            <div className={styles.pointLeftColumn}>
              <Typography>{t('SocialPowerSection.cards.1.title')}</Typography>
              <Spacer h={2} />
              <Typography variant="pSmallRegular" className={styles.pointText}>
                {t('SocialPowerSection.cards.1.text')}
              </Typography>
            </div>
            <div className={styles.pointimg}>
              <img src={CoinsIcon} alt="" />
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.pointLeftColumn}>
              <Typography>{t('SocialPowerSection.cards.2.title')}</Typography>
              <Spacer h={2} />
              <Typography variant="pSmallRegular" className={styles.pointText}>
                {t('SocialPowerSection.cards.2.text')}
              </Typography>
            </div>
            <div className={styles.pointimg}>
              <img src={TransferIcon} alt="" />
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.pointLeftColumn}>
              <Typography>{t('SocialPowerSection.cards.3.title')}</Typography>
              <Spacer h={2} />
              <Typography variant="pSmallRegular" className={styles.pointText}>
                {t('SocialPowerSection.cards.3.text')}
              </Typography>
            </div>
            <div className={styles.pointimg}>
              <img src={EarnIcon} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.img}>
          <img src={currentTheme === 'light' ? Coins : CoinsDark} alt="Coins" />
        </div>
      </div>
    </div>
  );
};
