/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/dot-notation */
import { useState, useEffect, useCallback, useRef } from 'react';
import cn from 'classnames';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BrightyLogo from '../../../images/brighty-logo.svg';
import BrightyLogoDark from '../../../images/brighty-logo-dark.svg';
import MenuIcon from '../../../images/Menu.svg';
import MenuIconDark from '../../../images/MenuDark.svg';
import CloseIcon from '../../../images/Close.svg';
import CloseIconDark from '../../../images/CloseDark.svg';
import SunIcon from '../../../images/sun.svg';
import MoonIcon from '../../../images/moon.svg';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';
import { Spacer } from '../Spacer/Spacer';
import { i18translateType } from '../../../types/i18translateType';
import { isLocalStorageAvailable } from '../../../utils/isLocalStorageAvailable';
import { Dropdown } from '../Dropdown/Dropdown';
import { useBool } from '../../../hooks/useBool';
import styles from './Header.module.scss';

const localesList = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'German',
    value: 'de',
  },
];

export const Header = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const isLanguageMenuOpen = useBool();
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  // useMemo(() => router.locale ?? 'en', [router.locale]);
  const { t }: i18translateType = useTranslation();
  const [isHeaderOnTop, setIsHeaderOnTop] = useState(true);
  const { switcher, themes, currentTheme } = useThemeSwitcher();

  // const otherLocales = locales?.filter((locale) => locale !== activeLocale);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleLanguageHandler = useCallback(
    (newLocaleName: any) => {
      i18n.changeLanguage(newLocaleName.value);
      localStorage.setItem('lang', newLocaleName.value);
    },
    [i18n],
  );

  const toggleDarkMode = () => {
    if (window && isLocalStorageAvailable()) {
      window.localStorage.setItem('theme', currentTheme === 'dark' ? 'light' : 'dark');
    }
    switcher({ theme: currentTheme === 'dark' ? themes.light : themes.dark });
  };

  useEffect(() => {
    const detectHeaderOnTop = () => {
      if (window.scrollY === 0) setIsHeaderOnTop(true);
      else if (window.scrollY > 0 && isHeaderOnTop) setIsHeaderOnTop(false);
    };
    detectHeaderOnTop();

    window.addEventListener('scroll', detectHeaderOnTop);
    return () => window.removeEventListener('scroll', detectHeaderOnTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickMobileLanguage = (e: any) => {
    e.stopPropagation();
    isLanguageMenuOpen.onTrue();
  };

  const closeMobileMenu = useCallback(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    closeMobileMenu();
  }, [location.pathname]);

  useEffect(() => {
    if (!isMobileMenuOpen && isLanguageMenuOpen.value) {
      isLanguageMenuOpen.onFalse();
    }
  }, [isMobileMenuOpen, isLanguageMenuOpen.value]);

  return (
    <header
      className={cn(styles.header, {
        [styles.headerTransparent]: !isHeaderOnTop,
      })}
    >
      <nav className={cn(styles.navbar)}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              src={currentTheme === 'dark' ? BrightyLogoDark : BrightyLogo}
              alt="Brighty logo"
              height={'30px'}
              width={'96px'}
            />
          </Link>
        </div>
        <div className={styles.careers}>
          <Link to="/about">
            <Typography variant="pMediumSemibold" className={styles.navLink}>
              {t('Header.links.about')}
            </Typography>
          </Link>
          <Spacer w={32} />
          <a
            className={styles.navLink}
            href="https://help.brighty.app/en/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="pMediumSemibold">{t('Header.links.help')}</Typography>
          </a>
          <Spacer w={32} />
          <a
            className={styles.navLink}
            href="https://brighty.bamboohr.com/jobs/?source=bamboohr"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="pMediumSemibold">{t('Header.links.hiring')}</Typography>
          </a>
        </div>
        <Spacer w={32} />
        <a
          className={styles.navLink}
          href="https://app.adjust.com/qr3gjca"
          target="_blank"
          rel="noreferrer"
        >
          <Button title={t('Landing.download')} className={styles.popupButton} />
        </a>
        <Spacer w={32} wm={16} />
        <div className={styles.careers}>
          <Dropdown
            onChange={toggleLanguageHandler}
            title={activeLocale}
            list={localesList}
            currentLocale={activeLocale}
            sectionClass={styles.languageToggler}
          />
          <Spacer w={23} />
          <Button type="icon" onClick={toggleDarkMode} className={styles.themeButton}>
            <img src={currentTheme === 'dark' ? SunIcon : MoonIcon} alt="" />
          </Button>
        </div>
        <div className={styles.burgerContainer} onClick={toggleMobileMenu}>
          <Button type="icon" className={styles.burgerButton}>
            <div className={styles.burgerIcon}>
              {!isMobileMenuOpen ? (
                <img src={currentTheme === 'dark' ? MenuIconDark : MenuIcon} alt="" />
              ) : (
                <img src={currentTheme === 'dark' ? CloseIconDark : CloseIcon} alt="" />
              )}
            </div>
          </Button>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div
          ref={mobileMenuRef}
          className={styles.overlay}
          onClick={setIsMobileMenuOpen.bind(this, false)}
        >
          <div
            className={cn(styles.burgerMenu, {
              [styles.burgerMenu__dark]: currentTheme === 'dark',
            })}
          >
            {!isLanguageMenuOpen.value ? (
              <>
                <div className={styles.burgerMenuItem}>
                  <Link to="/about">
                    <Typography variant="pSmallSemibold" className={styles.navLink}>
                      {t('Header.links.about')}
                    </Typography>
                  </Link>
                </div>
                <div className={styles.burgerMenuItem}>
                  <a
                    className={styles.navLink}
                    href="https://help.brighty.app/en/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="pMediumSemibold">{t('Header.links.help')}</Typography>
                  </a>
                </div>
                <div className={styles.burgerMenuItem}>
                  <a
                    className={styles.navLink}
                    href="https://brighty.bamboohr.com/jobs/?source=bamboohr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="pSmallSemibold">{t('Header.links.hiring')}</Typography>
                  </a>
                </div>
                <div className={styles.burgerMenuItem} onClick={handleClickMobileLanguage}>
                  <Typography variant="pSmallSemibold">{t('Header.links.language')}</Typography>
                </div>
                <div className={styles.burgerMenuItem} onClick={toggleDarkMode}>
                  <Typography variant="pSmallSemibold">{t('Header.links.colourMode')}</Typography>
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles.burgerMenuItem}
                  onClick={() => toggleLanguageHandler({ value: 'en' })}
                >
                  <Typography variant="pSmallSemibold">English</Typography>
                </div>
                <div
                  className={styles.burgerMenuItem}
                  onClick={() => toggleLanguageHandler({ value: 'de' })}
                >
                  <Typography variant="pSmallSemibold">Deutsch</Typography>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};
