import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { i18translateType } from '../../../types/i18translateType';
import { Typography } from '../../common/Typography/Typography';
import { Spacer } from '../../common/Spacer/Spacer';
import styles from './HeaderSection.module.scss';

export const HeaderSection = () => {
  const { t }: i18translateType = useTranslation();

  return (
    <div className={styles.root}>
      <Typography variant="h1" className={styles.title}>
        {t('about.title')}
      </Typography>
      <Spacer h={16} />
      <Typography variant="pMediumRegular" className={classNames(styles.description)}>
        {t('about.description')}
      </Typography>
    </div>
  );
};
