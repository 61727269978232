/* eslint-disable import/no-default-export */

import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/NotFoundPage/HeaderSection/HeaderSection';

const NotFoundPage = () => {
  return (
    <>
      <HeaderSection />
    </>
  );
};

export default withTranslation()(NotFoundPage);
