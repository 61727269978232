import { useTranslation } from 'react-i18next';
import { i18translateType } from '../../../types/i18translateType';
import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './MissionSection.module.scss';

export const MissionSection = () => {
  const { t }: i18translateType = useTranslation();

  return (
    <div className={styles.card}>
      <Typography variant="h4">{t('about.mission.title')}</Typography>
      <Spacer h={32} />
      <Typography variant="pMediumRegular">{t('about.mission.1')}</Typography>
      <Spacer h={16} />
      <Typography variant="pMediumRegular">{t('about.mission.2')}</Typography>
      <Spacer h={16} />
      <Typography variant="pMediumRegular">{t('about.mission.3')}</Typography>
      <Spacer h={16} />
      <Typography variant="pMediumRegular">{t('about.mission.4')}</Typography>
    </div>
  );
};
