import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Spacer } from '../Spacer/Spacer';
import { Typography } from '../Typography/Typography';
import styles from './Card.module.scss';

interface IProps {
  children?: ReactNode;
  title?: string;
  description?: string | ReactNode;
  className?: string;
}

export const Card: FC<IProps> = ({ children, title, description, className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Typography variant="h1" className={styles.title}>
        {title}
      </Typography>
      {Boolean(description) && (
        <>
          <Spacer h={8} />
          <Typography variant="pMediumRegular">{description}</Typography>
        </>
      )}
      {children}
    </div>
  );
};
