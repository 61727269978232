/* eslint-disable import/no-default-export */

import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/AboutPage/HeaderSection/HeaderSection';
import { MissionSection } from '../components/AboutPage/MissionSection/MissionSection';
import { TeamSection } from '../components/AboutPage/TeamSection/TeamSection';

const AboutPage = () => {
  return (
    <>
      <HeaderSection />
      <MissionSection />
      <TeamSection />
    </>
  );
};

export default withTranslation()(AboutPage);
