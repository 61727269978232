import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';

interface IProps {
  className?: string;
  children: ReactNode;
  align?: 'center' | 'left' | 'right';
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'pLargeSemibold'
    | 'pLargeRegular'
    | 'pMediumSemibold'
    | 'pMediumRegular'
    | 'pSmallSemibold'
    | 'pSmallRegular'
    | 'captionSemibold'
    | 'captionRegular';
}

export const Typography: FC<IProps> = ({
  className,
  children,
  variant = 'pMediumSemibold',
  align = 'left',
}) => {
  if (variant === 'h1') {
    return (
      <h1
        className={classNames(styles.text, styles[variant], className)}
        style={{ textAlign: align }}
      >
        {children}
      </h1>
    );
  }

  return (
    <p className={classNames(styles.text, styles[variant], className)} style={{ textAlign: align }}>
      {children}
    </p>
  );
};
