import { FC } from 'react';
import cn from 'classnames';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styles from './CloseButton.module.scss';

interface Props {
  onClick: VoidFunction;
  sectionClass: string;
}

export const CloseButton: FC<Props> = ({ onClick, sectionClass }) => {
  const { currentTheme } = useThemeSwitcher();
  const classNamesStyles = cn(styles.closeButton, sectionClass, {
    [styles.closeButtonDark]: currentTheme === 'dark',
  });

  return <button className={classNamesStyles} type="button" onClick={onClick} />;
};
