import { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';

const TermsAndConditionsPage = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const resize = () => {
      if (!iframe) {
        return;
      }
      iframe.style.height = iframe?.contentDocument?.body.scrollHeight + 'px';
    };
    if (iframe) {
      iframe.addEventListener('load', resize);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', resize);
      }
    };
  }, [iframeRef]);

  return (
    <iframe
      ref={iframeRef}
      src="fiat-terms-and-conditions.html"
      style={{ border: 'none', width: '100%' }}
      title="TermsAndConditions"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default withTranslation()(TermsAndConditionsPage);
