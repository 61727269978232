/* eslint-disable import/no-default-export */
import { useEffect } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { CookieBanner } from './components/common/CookieBanner/CookieBanner';
import { PopupsContextProvider } from './contexts/PopupsContext';
import { LanguageHOC } from './hoc/LanguageHOC';
import ScrollToTop from './hoc/ScrollToTop';
import { useBool } from './hooks/useBool';
import { AppRouter } from './routers/AppRouter';

function App() {
  const darkModeOn = useBool(false);

  useEffect(() => {
    if (window.localStorage.getItem('theme')) {
      if (window.localStorage.getItem('theme') === 'dark') {
        darkModeOn.onTrue();
      }
    } else {
      if (
        window &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        window.localStorage.setItem('theme', 'dark');
        darkModeOn.onTrue();
      }
    }
  }, [darkModeOn]);

  return (
    <div className="App">
      <ParallaxProvider>
        <ThemeSwitcherProvider
          defaultTheme={darkModeOn.value ? 'dark' : 'light'}
          themeMap={{
            dark: 'themes/darkThemeColors.css',
            light: 'themes/lightThemeColors.css',
          }}
        >
          <PopupsContextProvider>
            <BrowserRouter>
              <ScrollToTop />
              <LanguageHOC />
              <AppRouter />
              <CookieBanner />
            </BrowserRouter>
          </PopupsContextProvider>
        </ThemeSwitcherProvider>
      </ParallaxProvider>
    </div>
  );
}

export default App;
