import { FC } from 'react';
import classNames from 'classnames';
import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './MainSection.module.scss';

const Cards = [
  {
    title: 'General',
    link: '/fiat-terms-and-conditions',
  },
  {
    title: 'Cryptocurrency',
    link: '/cryptocurrency-offering-terms-and-conditions',
  },
  {
    title: 'Earning vaults',
    link: '/earning-vault-terms-and-conditions',
  },
  {
    title: 'Privacy Notice',
    link: '/privacy-policy',
  },
  {
    title: 'Cookie Notice',
    link: '/cookie-policy',
  },
];

interface CardProps {
  title: string;
  link?: string;
  text?: string;
}

const Card: FC<CardProps> = ({ title, link, text }) => {
  return (
    <a
      target="_blank"
      href={link}
      rel="noreferrer"
      className={classNames(styles.card, {
        [styles.card__disabled]: !link,
      })}
    >
      <Typography variant="h4">{title}</Typography>
      {!!text && <Typography variant="pMediumRegular">{text}</Typography>}
    </a>
  );
};

export const MainSection = () => {
  return (
    <div className={styles.root}>
      <Spacer h={32} />
      <div className={styles.wrapper}>
        <Typography variant="h3" className={styles.title}>
          Terms and Conditions
        </Typography>
        <Spacer h={32} />
        <div className={styles.cards}>
          {Cards.slice(0, 3).map((c) => (
            <Card key={c.title} link={c.link} title={c.title} />
          ))}
        </div>
        <Spacer h={64} />
        <Typography variant="h3" className={styles.title}>
          Privacy Protection
        </Typography>
        <Spacer h={32} />
        <div className={styles.cards}>
          {Cards.slice(3).map((c) => (
            <Card key={c.title} link={c.link} title={c.title} />
          ))}
        </div>
      </div>
    </div>
  );
};
