import { useTranslation } from 'react-i18next';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { i18translateType } from '../../../types/i18translateType';
import { Typography } from '../../common/Typography/Typography';
import { Spacer } from '../../common/Spacer/Spacer';
import AppStore from '../../../images/AppStore.svg';
import GooglePlay from '../../../images/GooglePlay.svg';
import AppStoreDark from '../../../images/AppStore-dark.svg';
import GooglePlayDark from '../../../images/GooglePlay-dark.svg';
import styles from './SocialSection.module.scss';
import { Button } from '../../common/Button/Button';

export const SocialSection = () => {
  const { t }: i18translateType = useTranslation();
  const { currentTheme } = useThemeSwitcher();

  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant="h1" align="center">
        {t('SocialSection.title')}
      </Typography>
      <Spacer h={64} hm={32} />
      <a
        className={styles.navLink}
        href="https://help.brighty.app/en/"
        target="_blank"
        rel="noreferrer"
      >
        <Button title={t('SocialSection.button')} />
      </a>
      <Spacer h={64} hm={32} />
      <div className={styles.buttons}>
        <a
          href="https://app.adjust.com/7d2bnfa?redirect_ios=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
          target="_blank"
          rel="noreferrer"
        >
          <img src={currentTheme === 'light' ? AppStore : AppStoreDark} alt="App Store" />
        </a>
        <a
          href="https://app.adjust.com/qr3gjca?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
          target="_blank"
          rel="noreferrer"
        >
          <img src={currentTheme === 'light' ? GooglePlay : GooglePlayDark} alt="Google play"/>
        </a>
      </div>
    </div>
  );
};
