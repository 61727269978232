import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import Nikolai from '../../../images/Nikolai.png';
import Roger from '../../../images/RogerRound.png';
import Nara from '../../../images/Nara.png';
import Arslan from '../../../images/Arslan.png';
import Michael from '../../../images/Michael.png';
import Javeria from '../../../images/Javeria.png';
import { i18translateType } from '../../../types/i18translateType';
import { Typography } from '../../common/Typography/Typography';
import { Spacer } from '../../common/Spacer/Spacer';
import LinkedinIcon from '../../../images/Linkedin.svg';
import LinkedinIconDark from '../../../images/LinkedinDark.svg';
import styles from './TeamSection.module.scss';

const members = [
  {
    name: 'Roger Buerli',
    img: Roger,
    link: 'https://www.linkedin.com/in/roger-alois-buerli-074702215/',
    position: 'CEO',
  },
  {
    name: 'Nick Denisenko',
    img: Nikolai,
    link: 'https://www.linkedin.com/in/be1ive/',
    position: 'Co-founder & CTO',
  },
  {
    name: 'Nargiz Khanjanbay',
    img: Nara,
    link: 'https://www.linkedin.com/in/nargizidrisova/',
    position: 'HRD',
  },
  {
    name: 'Arslan Ibrahim',
    img: Arslan,
    link: 'https://www.linkedin.com/in/arslani/',
    position: 'CPO',
  },
  {
    name: 'Javeria Fazil',
    img: Javeria,
    link: 'https://www.linkedin.com/in/javeria-fazil-542a90187/',
    position: 'Associate Analyst',
  },
  {
    name: 'Michael Waniek',
    img: Michael,
    position: 'Head of Customer Support',
    positionMobile: 'HCS',
    link: 'https://www.linkedin.com/in/michael-w-3925761a/',
  },
];

export const TeamSection = () => {
  const { t }: i18translateType = useTranslation();
  const { currentTheme } = useThemeSwitcher();

  const linkedin = useMemo(
    () => (currentTheme === 'light' ? LinkedinIcon : LinkedinIconDark),
    [currentTheme],
  );

  return (
    <div className={styles.card}>
      <Typography variant="h4">{t('about.team')}</Typography>
      <Spacer h={32} />
      <div className={styles.members}>
        {members.map((m) => (
          <div key={m.name} className={styles.member}>
            <div className={styles.member__img}>
              <img src={m.img} alt={m.name + ' photo'} width={160} height={160} />
            </div>
            <Spacer h={16} />
            <div className={styles.info}>
              <Typography variant="pMediumSemibold">{m.name}</Typography>
            </div>
            <Spacer h={2} />
            <a className={styles.row} href={m.link} target="_blank" rel="noreferrer">
              <Typography variant="pSmallRegular" className={styles.position}>
                {m.position}
              </Typography>
              <Typography variant="pSmallRegular" className={styles.position__mobile}>
                {m.positionMobile ?? m.position}
              </Typography>
              <img src={linkedin} width={16} height={16} className={styles.linkedin} alt="linkedin" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
