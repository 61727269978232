import { useEffect } from 'react';
import i18n from '../i18n/config';

const locales: { [locale: string]: string } = {
  en: 'en',
  de: 'de',
};

export const LanguageHOC = () => {
  useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (lang && locales[lang]) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return null;
};
