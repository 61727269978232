/* eslint-disable import/no-default-export */
import { FC, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/HomePage/HeaderSection/HeaderSection';
import { ParallaxSection } from '../components/HomePage/ParallaxSection/ParallaxSection';
import { PowerSection } from '../components/HomePage/PowerSection/PowerSection';
import { QuoteSection } from '../components/HomePage/QuoteSection/QuoteSection';

interface IProps {
  landing?: boolean;
}

const HomePage: FC<IProps> = ({ landing = false }) => {
  useEffect(() => {
    document.title = 'Brighty app Financial and Earning app – See Your Money Grow';
  }, []);

  if (landing) {
    return (
      <>
        <HeaderSection landing={true} />
        <ParallaxSection landing={true} />
        <PowerSection landing={true} />
        <QuoteSection />
      </>
    );
  }

  return (
    <>
      <HeaderSection />
      <ParallaxSection />
      <PowerSection />
      <QuoteSection />
    </>
  );
};

export default withTranslation()(HomePage);
