export function eraseCookie(name: string) {
  const expireDate = new Date();
  expireDate.setSeconds(expireDate.getSeconds() + 1);
  document.cookie =
    name +
    '=' +
    'null' +
    ';domain=' +
    window.location.hostname +
    ';path=/' +
    ';expires=' +
    expireDate.toUTCString();
}
