import { FC, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface IProps {
  children?: ReactNode;
  type?: 'text' | 'button' | 'icon';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  title?: string;
  color?: 'accent' | 'default' | 'white' | 'secondary' | 'grey';
}

export const Button: FC<IProps> = ({
  children,
  type = 'button',
  onClick,
  disabled = false,
  className,
  title,
  color = 'default',
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
    if (ref.current) {
      ref.current?.blur();
    }
  };

  return (
    <button
      ref={ref}
      className={classNames(
        styles.button,
        className,
        styles[`${color}Theme`],
        styles[`${type}Type`],
        {
          [styles.buttonDisabled]: disabled,
        },
      )}
      onClick={handleClick}
      type="button"
    >
      {title ?? children}
    </button>
  );
};
