import { FC } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useTranslation } from 'react-i18next';
import Picture4 from '../../../images/picture4.png';
import Picture5 from '../../../images/picture5.png';
import Picture6 from '../../../images/picture6.png';
import Picture4Dark from '../../../images/picture4Dark.png';
import Picture5Dark from '../../../images/picture5Dark.png';
import Picture6Dark from '../../../images/picture6Dark.png';
import { Card } from '../../common/Card/Card';
import { Spacer } from '../../common/Spacer/Spacer';
import { i18translateType } from '../../../types/i18translateType';
import styles from './ParallaxSection.module.scss';

interface IProps {
  landing?: boolean;
}

export const ParallaxSection: FC<IProps> = ({ landing = false }) => {
  const { t }: i18translateType = useTranslation();
  const { currentTheme } = useThemeSwitcher();

  const Card4 = () => {
    return (
      <Card title={t('ParallaxSection.4.title')} description={t('ParallaxSection.4.point')}>
        <div className={styles.img}>
          <img src={currentTheme === 'light' ? Picture4 : Picture4Dark} alt="Swap account" />
        </div>
      </Card>
    );
  };

  const Card5 = () => {
    return (
      <Card title={t('ParallaxSection.5.title')} className={styles.greenCard}>
        <div className={styles.img}>
          <img src={currentTheme === 'light' ? Picture5 : Picture5Dark} alt="Vault" />
        </div>
      </Card>
    );
  };

  const Card6 = () => {
    return (
      <Card title={t('ParallaxSection.6.title')} description={t('ParallaxSection.6.point')}>
        <div className={styles.img}>
          <img src={currentTheme === 'light' ? Picture6 : Picture6Dark} alt="Vault" />
        </div>
      </Card>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.columnMobile}>
        <Card4 />
        <Spacer hm={32} />
        <Card5 />
        <Spacer hm={32} />
        <Card6 />
      </div>
      <div className={styles.column}>
        <Parallax speed={10}>
          <Card4 />
        </Parallax>
      </div>
      <div className={styles.column}>
        <Parallax speed={40}>
          <Card5 />
          <Spacer h={16} />
          <Card6 />
        </Parallax>
      </div>
    </div>
  );
};
