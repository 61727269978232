/* eslint-disable import/no-default-export */
import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/TermsAndPolicies/HeaderSection/HeaderSection';
import { MainSection } from '../components/TermsAndPolicies/MainSection/MainSection';

const TermsAndPoliciesPage = () => {
  return (
    <>
      <HeaderSection />
      <MainSection />
    </>
  );
};

export default withTranslation()(TermsAndPoliciesPage);
