import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Typography } from '../Typography/Typography';
import { Spacer } from '../Spacer/Spacer';
import { i18translateType } from '../../../types/i18translateType';
import LinkedinIcon from '../../../images/LinkedinIcon.svg';
import LinkedinIconDark from '../../../images/LinkedinIconDark.svg';
import InstagramIcon from '../../../images/InstagramIcon.svg';
import InstagramIconDark from '../../../images/InstagramIconDark.svg';
import TwitterIcon from '../../../images/TwitterIcon.svg';
import TwitterIconDark from '../../../images/TwitterIconDark.svg';
import DiscordIcon from '../../../images/DiscordIcon.svg';
import DiscordIconDark from '../../../images/DiscordIconDark.svg';
import FacebookIcon from '../../../images/FacebookIcon.svg';
import FacebookIconDark from '../../../images/FacebookIconDark.svg';
import CHF from '../../../images/CHF.svg';
import styles from './Footer.module.scss';

export const Footer = () => {
  const { t }: i18translateType = useTranslation();
  const { currentTheme } = useThemeSwitcher();
  // const [query] = useLanguageQuery();
  const links = useMemo(
    () => [
      {
        text: 'Footer.home',
        href: '/',
      },
      {
        text: 'Footer.careers',
        href: 'https://brighty.bamboohr.com/jobs/?source=bamboohr',
        blank: true,
      },
      {
        text: 'Footer.help',
        href: 'https://help.brighty.app/en/',
        blank: true,
      },
      {
        text: 'Footer.imprint',
        href: '/imprint',
      },
      {
        text: 'Footer.termsAndNotices',
        href: '/termsAndPolicies',
      },
      // {
      //   text: '+41445869740',
      //   href: 'tel:+41445869740',
      // },
    ],
    [],
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.card}>
        <div className={styles.textBlock}>
          <div className={styles.chf}>
            <img
              src={CHF}
              alt="CHF"
              height={24}
              width={24}
              style={{ width: '24px', height: '24px' }}
            />
            <Typography className={styles.textBlockText} variant="captionSemibold">
              {t('Footer.chf')}
            </Typography>
          </div>
          <Typography className={styles.textBlockText} variant="captionRegular">
            {t('Footer.text.text1')}
          </Typography>
          <Spacer h={4} />
          <Typography className={styles.textBlockText} variant="captionRegular">
            {t('Footer.text.text2')}
          </Typography>
          <Spacer h={16} />
          <Typography className={styles.textBlockText} variant="captionRegular">
            {t('Footer.text.year')}
          </Typography>
        </div>
        <Spacer h={1} />
        <div className={styles.top}>
          <nav className={styles.nav}>
            {links.map((l) => (
              <div key={l.text} className={styles.linkContainer}>
                {l.blank ? (
                  <a
                    className={styles.navLink}
                    href={l.href}
                    target={l.blank ? '_blank' : undefined}
                    rel="noreferrer"
                  >
                    <Typography className={styles.navText} variant="pSmallRegular">
                      {t(l.text)}
                    </Typography>
                  </a>
                ) : (
                  <Link to={l.href} className={styles.navLink}>
                    <Typography className={styles.navText} variant="pSmallRegular">
                      {t(l.text)}
                    </Typography>
                  </Link>
                )}
              </div>
            ))}
          </nav>
          <div className={styles.icons}>
            <a href="https://www.instagram.com/brighty.app/" target="_blank" rel="noreferrer">
              <img
                src={currentTheme === 'dark' ? InstagramIconDark : InstagramIcon}
                alt="Instagram"
                height={'20px'}
                width={'20px'}
              />
            </a>
            <a href="https://www.linkedin.com/company/brightyapp/" target="_blank" rel="noreferrer">
              <img
                src={currentTheme === 'dark' ? LinkedinIconDark : LinkedinIcon}
                alt="Linkedin"
                height={'20px'}
                width={'20px'}
              />
            </a>
            <a href="https://twitter.com/brightyapp" target="_blank" rel="noreferrer">
              <img
                src={currentTheme === 'dark' ? TwitterIconDark : TwitterIcon}
                alt="Twitter"
                height={'20px'}
                width={'20px'}
              />
            </a>
            <a href="https://www.facebook.com/brightyapp" target="_blank" rel="noreferrer">
              <img
                src={currentTheme === 'dark' ? FacebookIconDark : FacebookIcon}
                alt="Facebook"
                height={'20px'}
                width={'20px'}
              />
            </a>
            <a href="https://discord.gg/JER7TYKAFT" target="_blank" rel="noreferrer">
              <img
                src={currentTheme === 'dark' ? DiscordIconDark : DiscordIcon}
                alt="Discord"
                height={'20px'}
                width={'20px'}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
