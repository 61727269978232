/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-default-export */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      common: require('./locales/en/common.json'),
    },
    de: {
      common: require('./locales/de/common.json'),
    },
  },
  ns: ['common'],
  defaultNS: 'common',
});

i18n.languages = ['en', 'de'];

export default i18n;
