import { Outlet, Route, Routes } from 'react-router-dom';
import AboutPage from '../containers/AboutPage';
import CookiePolicyPage from '../containers/CookiePolicyPage';
import CryptocurrencyOfferingTermsAndConditions from '../containers/CryptocurrencyOfferingTermsAndConditions';
import EarningVaultTermsAndConditionsPage from '../containers/EarningVaultTermsAndConditionsPage';
import HomePage from '../containers/HomePage';
import ImprintPage from '../containers/ImprintPage';
import PrivacyPolicyPage from '../containers/PrivacyPolicyPage';
import TermsAndConditionsPage from '../containers/TermsAndConditionsPage';
import TermsAndPoliciesPage from '../containers/TermsAndPoliciesPage';
import { MainLayout } from '../layouts';
import NotFoundPage from '../containers/404';

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }
      >
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/earnapp" element={<HomePage landing={true} />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/termsAndPolicies" element={<TermsAndPoliciesPage />} />
        <Route path="/imprint" element={<ImprintPage />} />
      </Route>
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        path="/earning-vault-terms-and-conditions"
        element={<EarningVaultTermsAndConditionsPage />}
      />
      <Route
        path="/cryptocurrency-offering-terms-and-conditions"
        element={<CryptocurrencyOfferingTermsAndConditions />}
      />
      <Route path="/fiat-terms-and-conditions" element={<TermsAndConditionsPage />} />
      <Route path="/cookie-policy" element={<CookiePolicyPage />} />
    </Routes>
  );
};
