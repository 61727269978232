/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export function useOutsideClick(refs, onClickOutside) {
  useEffect(() => {
    function onClick(e) {
      const isChildClicked = !!refs.find((ref) => ref.current && ref.current.contains(e.target));
      if (!isChildClicked) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', onClick);
    document.addEventListener('touchstart', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
      document.removeEventListener('touchstart', onClick);
    };
  }, [refs]);
}
