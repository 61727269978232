import { useTranslation } from 'react-i18next';
import RogerPhoto from '../../../images/Roger.png';
import { i18translateType } from '../../../types/i18translateType';
import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './QuoteSection.module.scss';

export const QuoteSection = () => {
  const { t }: i18translateType = useTranslation();

  return (
    <>
      <div className={styles.card}>
        <div className={styles.photo}>
          <img src={RogerPhoto} alt="RogerBuerli_photo" />
        </div>
        <Spacer hm={32} />
        <div className={styles.column}>
          <Typography className={styles.name} variant="pSmallRegular">
            Roger Buerli
            <br />
            {t('QuoteSection.role')}
          </Typography>
          <Spacer h={16} />
          <Typography variant="h2">{t('QuoteSection.quote')}</Typography>
        </div>
      </div>
    </>
  );
};
