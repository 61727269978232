/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useCallback } from 'react';
import { useBool } from '../hooks/useBool';

interface ContextValue {
  closeAllPopups: () => void;
  togglePopupOrder: () => void;
  isPopupOrderOpen: boolean;
}

interface Props {
  children: ReactNode;
}

const PopupsContext = createContext<ContextValue>({} as any);

const PopupsContextProvider = ({ children }: Props) => {
  const isPopupOrderOpen = useBool(false);

  const closeAllPopups = useCallback(() => {
    isPopupOrderOpen.onFalse();
  }, []);

  const togglePopupOrder = useCallback(() => {
    isPopupOrderOpen.onToggle();
  }, []);

  const contextValue = {
    closeAllPopups,
    togglePopupOrder,
    isPopupOrderOpen: isPopupOrderOpen.value,
  };

  return <PopupsContext.Provider value={contextValue}>{children}</PopupsContext.Provider>;
};

export { PopupsContextProvider, PopupsContext };
