import { FC, ReactNode, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { CloseButton } from '../../common/CloseButton/CloseButton';
import styles from './Popup.module.scss';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  containerSectionClass?: string;
  showCloseButton?: boolean;
  children: ReactNode;
}

export const Popup: FC<Props> = ({
  children,
  isOpen,
  onClose,
  containerSectionClass,
  showCloseButton,
}) => {
  const { currentTheme } = useThemeSwitcher();
  const closePopupOnOverlay = useCallback(
    (evt: any) => {
      if (evt.target === evt.currentTarget) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    function closePopupOnEsc(evt: any) {
      if (evt.key === 'Escape') {
        onClose();
      }
    }

    if (isOpen) {
      window.addEventListener('keyup', closePopupOnEsc);
      document.body.style.overflow = 'hidden';
      return () => {
        window.removeEventListener('keyup', closePopupOnEsc);
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen, onClose]);

  const classNamesMain = cn(styles.popup, { [styles.popupOpened]: isOpen });
  const classNamesPopupContainer = cn(styles.popupContainer, containerSectionClass, {
    [styles.popupContainerDark]: currentTheme === 'dark',
  });

  return (
    <div className={classNamesMain} onClick={closePopupOnOverlay}>
      <div className={classNamesPopupContainer}>
        {children}
        {showCloseButton && <CloseButton sectionClass={styles.closeButton} onClick={onClose} />}
      </div>
    </div>
  );
};
